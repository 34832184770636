import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>System of Systems</title>
      </Helmet>
      Sorry, page not found.
    </Layout>
  );
};

export default NotFoundPage;
